(function () {
    'use strict';
    var app = angular.module("cusoAnalyzer");
    app.factory('excelExport', function () {
        return {
            exportCusoSearchToExcel: function (fields, jsonData, reportTitle, showLabel, header) {
                var fieldsIndexes = "";
                //var JSONData = vm.ResultString;
                //var ReportTitle = "CUSOs";
                //var ShowLabel = true;
                //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
                var arrData = typeof jsonData != 'object' ? JSON.parse(jsonData) : jsonData;
                var CSV = header + '\r\n';
                //Set Report title in first row or line
                //CSV += ReportTitle + '\r\n\n';
                var fieldIndex = 0;
                //This condition will generate the Label/Header
                if (showLabel) {
                    var row = "";
                    //This loop will extract the label from 1st index of on array
                    for (var index in arrData[0]) {
                        //Now convert each value to string and comma-seprated
                        if (fields.indexOf('|' + index + '|') > -1) {
                            fieldsIndexes += fieldIndex + ',';
                            row += index + ',';
                        }
                        fieldIndex++;
                    }
                    row = row.slice(0, -1);
                    //append Label row with line break
                    CSV += row + '\r\n';
                }
                //1st loop is to extract each row
                for (var i = 0; i < arrData.length; i++) {
                    var row = "";
                    //2nd loop will extract each column and convert it in string comma-seprated
                    fieldIndex = 0;
                    for (var index in arrData[i]) {
                        if (fieldsIndexes.indexOf(fieldIndex + ',') > -1)
                            row += '"' + arrData[i][index] + '",';
                        fieldIndex++;
                    }
                    row.slice(0, row.length - 1);
                    //add a line break after each row
                    CSV += row + '\r\n';
                }
                if (CSV == '') {
                    alert("Invalid data");
                    return;
                }
                //Generate a file name
                var fileName = reportTitle.replace(/ /g, "_");
                //Initialize file format you want csv or xls
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
                //this trick will generate a temp <a /> tag
                var link = document.createElement("a");
                link.href = uri;
                //set the visibility hidden so it will not effect on your web-layout
                link.style = "visibility:hidden";
                link.download = fileName + ".csv";
                //this part will append the anchor tag and remove it after automatic click
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
            ,
            exportCusoProfileToExcel: function (fieldsGeneral, jsonDataGeneral, jsonDataService, fieldsOwner, jsonDataOwner, fieldsClient, jsonDataClient, reportTitle) {
                var fieldsIndexes = "";
                //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
                var arrDataGeneral = typeof jsonDataGeneral != 'object' ? JSON.parse(jsonDataGeneral) : jsonDataGeneral;
                var CSV = "General Information\r\n";
                CSV += fieldsGeneral + '\r\n';
                var row = "";
                row = '"' + arrDataGeneral.Name + '","' + arrDataGeneral.Address + '","' + arrDataGeneral.City + '","' + arrDataGeneral.State + '","'
                    + arrDataGeneral.Zipcode + '","' + arrDataGeneral.Website + '","' + (arrDataGeneral.IsWhollyOwned == "TRUE" ? "Yes" : "No") + '","'
                    + (arrDataGeneral.HasNonCuOwner == "TRUE" ? "Yes" : "No") + '"\r\n';
                CSV += row + '\r\n';
                //---------- service report ---------------------
                fieldsIndexes = "";
                var arrDataService = typeof jsonDataService != 'object' ? JSON.parse(jsonDataService) : jsonDataService;
                CSV += 'CUSO Services' + '\r\n';
                row = "";
                for (var index in arrDataService) {
                    row += '"' + arrDataService[index].Name + '",';
                }
                row.slice(0, row.length - 1);
                CSV += row + '\r\n\n';
                //--------------- CU Owners -------------------------------
                //fieldsIndexes = "";
                //var arrDataOwner = typeof jsonDataOwner != 'object' ? JSON.parse(jsonDataOwner) : jsonDataOwner;
                //CSV += "Credit Union Owners\r\n";
                //row = "";
                //CSV += fieldsOwner + '\r\n';
                //row = '';
                //for (var index in arrDataOwner) {
                //    row += '"' + arrDataOwner[index].Name + '",' + '"$' + arrDataOwner[index].TotalInvestments + '",' + '"$' + arrDataOwner[index].TotalAmountLoaned + '",' + '"$' + arrDataOwner[index].TotalCashOutlay + '"\r\n';
                //}
                //row.slice(0, row.length - 1);
                //CSV += row + '\r\n';
                fieldsIndexes = "";
                var arrDataOwner = typeof jsonDataOwner != 'object' ? JSON.parse(jsonDataOwner) : jsonDataOwner;
                CSV += "Credit Union Owners\r\n";
                row = "";
                for (var index in arrDataOwner) {
                    row += '"' + arrDataOwner[index].ItemName + '"\r\n';
                }
                row.slice(0, row.length - 1);
                CSV += row + '\r\n';
                //--------------- CU Clients -------------------------------
                fieldsIndexes = "";
                var arrDataClient = typeof jsonDataClient != 'object' ? JSON.parse(jsonDataClient) : jsonDataClient;
                CSV += "Additional Credit Union Clients\r\n";
                row = "";
                for (var index in arrDataClient) {
                    row += '"' + arrDataClient[index].ItemName + '"\r\n';
                }
                row.slice(0, row.length - 1);
                CSV += row + '\r\n';
                if (CSV == '') {
                    alert("Invalid data");
                    return;
                }

                //Generate a file name
                var fileName = reportTitle.replace(/ /g, "_");
                //Initialize file format you want csv or xls
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
                //this trick will generate a temp <a /> tag
                var link = document.createElement("a");
                link.href = uri;
                //set the visibility hidden so it will not effect on your web-layout
                link.style = "visibility:hidden";
                link.download = fileName + ".csv";
                //this part will append the anchor tag and remove it after automatic click
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
            //------------------------------------------------------------------------------------------
            exportCuSearchToExcel: function (data) {
                var fieldsIndexes = "";
                var arrData = typeof data != 'object' ? JSON.parse(data) : data;
                var CSV = "Credit Union Name,Number of CUSO Investments\r\n";
                var row = '';
                for (var index in arrData) {
                    row += '"' + arrData[index].Name + '","' + arrData[index].CusoCount + '"\r\n';
                }
                CSV += row;

                if (CSV == '') {
                    alert("Invalid data");
                    return;
                }
                //Generate a file name
                var fileName = "CU Search Results";
                //Initialize file format you want csv or xls
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
                //this trick will generate a temp <a /> tag
                var link = document.createElement("a");
                link.href = uri;
                //set the visibility hidden so it will not effect on your web-layout
                link.style = "visibility:hidden";
                link.download = fileName + ".csv";
                //this part will append the anchor tag and remove it after automatic click
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
            //------------------------------------------------------------------------------------------
            exportCuProfileToExcel: function (fieldsGeneral, jsonDataGeneral, fieldsPerformance, jsonDataPerformance,
                fieldsInvested, jsonDataInvested, fieldsUtilized, jsonDataUtilized, reportTitle) {
                var fieldsIndexes = "";
                //If JSONData is not an object then JSON.parse will parse the JSON string in an Object
                var arrDataGeneral = typeof jsonDataGeneral != 'object' ? JSON.parse(jsonDataGeneral) : jsonDataGeneral;
                var arrDataPerformance = typeof jsonDataPerformance != 'object' ? JSON.parse(jsonDataPerformance) : jsonDataPerformance;
                var CSV = "Contact Information\r\n";
                CSV += fieldsGeneral + '\r\n';
                var row = "";
                row = '"' + arrDataGeneral.Name + '","' + arrDataGeneral.Address + ' ' + arrDataGeneral.City + ' ' + arrDataGeneral.StateCode + ' '
                    + arrDataGeneral.ZipCode + '","' + arrDataPerformance.NumberOfBranches + '","'
                    + arrDataGeneral.Telephone + '","' + arrDataGeneral.FaxNumber + '","'
                    + arrDataGeneral.CharterNumber + '","'
                    + arrDataGeneral.URL + '"\r\n';
                CSV += row + '\r\n';
                //---------- performance report ---------------------

                CSV += 'Performance Profile' + '\r\n';
                CSV += fieldsPerformance + '\r\n';
                row = "";
                row = '"$' + arrDataPerformance.Assets + '","$' + arrDataPerformance.Loans + '","$' + arrDataPerformance.Investments + '","$'
                    + arrDataPerformance.Capital + '","'
                    + arrDataPerformance.Members + '","' + arrDataPerformance.Employees.toFixed(0) + '","' + arrDataPerformance.ShareGrowth.toFixed(1) + '%","'
                    + arrDataPerformance.LoanGrowth.toFixed(1) + '%","'
                    + arrDataPerformance.Roa.toFixed(2) + '%"\r\n';
                CSV += row + '\r\n';
                //--------------- CUSOs invested -------------------------------
                var arrDataInvested = typeof jsonDataInvested != 'object' ? JSON.parse(jsonDataInvested) : jsonDataInvested;
                CSV += "CUSOs Invested In\r\n";
                row = "";
                CSV += fieldsInvested + '\r\n';
                row = '';
                for (var index in arrDataInvested) {
                    row += '"' + arrDataInvested[index].Name + '",' + '"$' + arrDataInvested[index].TotalInvestments + '",'
                        + '"$' + arrDataInvested[index].TotalAmountLoaned + '",' + '"$' + arrDataInvested[index].TotalCashOutlay + '"\r\n';
                }
                //row.slice(0, row.length - 1);
                CSV += row + '\r\n';
                //--------------- CUSOs utilized -------------------------------
                var arrDataUtilized = typeof jsonDataUtilized != 'object' ? JSON.parse(jsonDataUtilized) : jsonDataUtilized;
                CSV += "CUSOs Utilized\r\nCUSO\r\n";
                row = "";
                for (var index in arrDataUtilized) {
                    row += '"' + arrDataUtilized[index].ItemName + '"\r\n';
                }
                row.slice(0, row.length - 1);
                CSV += row + '\r\n';
                if (CSV == '') {
                    alert("Invalid data");
                    return;
                }

                //Generate a file name
                var fileName = reportTitle.replace(/ /g, "_");
                //Initialize file format you want csv or xls
                var uri = 'data:text/csv;charset=utf-8,' + escape(CSV);
                //this trick will generate a temp <a /> tag
                var link = document.createElement("a");
                link.href = uri;
                //set the visibility hidden so it will not effect on your web-layout
                link.style = "visibility:hidden";
                link.download = fileName + ".csv";
                //this part will append the anchor tag and remove it after automatic click
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        };
    });
})();

