(function () {
    'use strict';
    var app = angular.module("cusoAnalyzer");
    app.directive("logoutDirective", function () {
        return {
            templateUrl: 'logout.html',
            controller: 'LogoutCtrl',
            controllerAs: 'vm'

        };
    });
    angular.module('cusoAnalyzer')
        .controller('LogoutCtrl', LogoutCtrl);

    LogoutCtrl.$inject = ['$location', '$route', 'cusoSession', '$stateParams'];

    function LogoutCtrl($location, $route, cusoSession, $stateParams) {
        var vm = this;

        init();

        function init() {
            var trackId = cusoSession.getTrackId();
            cusoSession.logout(trackId).then(function (data) {
                
            }, function (error) {
                return 0;
            });
        }
    }
})();