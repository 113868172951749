
(function () {
    'use strict';
    var app = angular.module("cusoAnalyzer");
    app.directive("nameSearch", function () {
        return {
            templateUrl: 'utility/nameSearch.html',
            controller: 'NameSearchCtrl',
            controllerAs: 'vm',
            bindToController: true,
            restrict: 'E',
            scope: {
                listType: "=",
                selectedCuName: "=",
                exclusionListName: "=",
                cdsId: "=",
                selectionList: "="
            }

        };
    });
    angular.module('cusoAnalyzer')
        .controller('NameSearchCtrl', NameSearchCtrl);

    NameSearchCtrl.$inject = ['$location', 'cusoApi'];

    function NameSearchCtrl($location, cusoApi) {
        var vm = this;
        vm.search = search;
        vm.cusosByName = cusosByName;
        vm.creditunionsByName = creditunionsByName;
        vm.criteria = {};

        function search(val) {
            if (val.length < 3) return;
            if (vm.listType === 'cuso') return cusosByName(val);
            else return creditunionsByName(val);
        }

        function cusosByName(val) {
            //if (val.length < 3) return;
            vm.criteria.Name = val;
            vm.criteria.CusoCdsid = vm.cdsId;
            vm.criteria.ExclusionListName = vm.exclusionListName;
            return cusoApi.cuso.byName(vm.criteria).then(function (data) {
                vm.selectionList = data.Data;
                return data.Data.map(function (item) {
                    return item.ItemName;
                });
            });
        }

        function creditunionsByName(val) {
            //if (val.length < 3) return;
            vm.criteria.Name = val;
            vm.criteria.CusoCdsid = vm.cdsId;
            vm.criteria.ExclusionListName = vm.exclusionListName;
            return cusoApi.cu.byName(vm.criteria).then(function (data) {
                vm.selectionList = data.Data;
                return data.Data.map(function (item) {
                    return item.ItemName;
                });
            });
        }
    }
})();