
(function () {
    'use strict';
    var app = angular.module("cusoAnalyzer");
    app.directive("cuSearchDirective", function () {
        return {
            templateUrl: 'cuSearch.html',
            controller: 'CuSearchCtrl',
            controllerAs: 'vm'

        };
    });
    angular.module('cusoAnalyzer')
        .controller('CuSearchCtrl', CuSearchCtrl);

    CuSearchCtrl.$inject = ['$location', 'cusoApi', 'cusoSession', '$stateParams', 'cuSearchCriteria', 'excelExport'];

    function CuSearchCtrl($location, cusoApi, cusoSession, $stateParams, cuSearchCriteria, excelExport) {

        var vm = this;
        vm.serviceid = parseInt($stateParams.id);
        vm.init = init;
        vm.search = search;
        vm.sort = sort;
        vm.selectPage = selectPage;      
        vm.checkService = checkService;
        vm.checkAsset = checkAsset;
        vm.reset = reset;
        vm.checkAllService = checkAllService;
        vm.showServiceError = false;
        vm.showAssetError = false;
        vm.states = [];
        vm.services = [];
        vm.assets = [];
        vm.allServices = true;
        vm.Model = cuSearchCriteria.get();
        vm.criteria = {};
        vm.initial = true;
        vm.moduleType = 'cu';
        vm.exclusionListName = '';
        vm.SelectionList = [];
        vm.selectedServiceNames = '';
        vm.exportToExcel = exportToExcel;
        init();

        function init() {
            //valid session?
            if (vm.Model.TrackId == undefined) {
                $location.path('/login');
            }
            vm.Model = cuSearchCriteria.get();
            vm.assets = [
                    { AssetId: 1, Name: 'All', Selected: true },
                    { AssetId: 2, Name: 'Over $1B', Selected: true },
                    { AssetId: 3, Name: '$500M - $1B', Selected: true },
                    { AssetId: 4, Name: '$250M - $500M', Selected: true },
                    { AssetId: 5, Name: '$100M - $250M', Selected: true },
                    { AssetId: 6, Name: 'Under $100M', Selected: true }
            ];

            //state list
            cusoApi.selection.states().then(function(data) {
                    vm.states = data;
                },
                function(error) {
                });

            //service list
            if (vm.serviceid > 0) {
                vm.Model = cuSearchCriteria.resetSearch();
                search(1);

            } else if (!cuSearchCriteria.isNewSearch()) {
                search(0, 0);
            }

            
        }

        function search(newSearch) {
            vm.Model.Assets = [];
          
            vm.ResultList = [];           
            if (newSearch)
                vm.Model.PageNum = 1;

            //get all checked assets
            angular.forEach(vm.assets, function (value, key, obj) {
                if (value.Selected)
                    vm.Model.Assets.push(value.AssetId);
            });
            if (vm.Model.Name != undefined)
                vm.Model.Name = vm.Model.Name.split(' (')[0];
            else
                vm.Model.Name = '';
            cuSearchCriteria.set(vm.Model);
            if (vm.Model.ServiceIds.length < 1) {
                vm.showServiceError = true;
                vm.showAssetError = false;
                return;
            }
            else if (vm.Model.Assets.length < 1) {
                vm.showAssetError = true;
                vm.showServiceError = false;
                return;
            } else {
                vm.showServiceError = false;
                vm.showAssetError = false;
            }
            vm.Model.Isexport = false;
           cusoApi.search.cu(vm.Model).then(function (data) {              
               vm.ResultList = data.Data.ResultList;   
               vm.PageNum = data.Data.PageNum;
               vm.TotalResultCount = data.Data.TotalResultCount;
            }, function (error) {
            });          
        }

        function checkAllService() {
            if (vm.allServices) {
                angular.forEach(vm.services,
                    function(value, key, obj) {
                        value.Selected = true;
                    });
            } else {
                angular.forEach(vm.services,
                    function (value, key, obj) {
                        value.Selected = false;
                    });
            }
        }

        function checkService(option) {

            if (option.ServiceId === option.ParentServiceId) {
                option.Selected = !option.Selected;
                //all the children whill be conformed
                angular.forEach(vm.services, function (value, key, obj) {
                    if (value.ParentServiceId == option.ServiceId)
                        value.Selected = option.Selected;
                });
            } else {
                option.Selected = !option.Selected;
            }
            if (option.Selected)
                vm.allServices = false;

        }

        function checkAsset(option) {
            if (option.AssetId === 1) { // all option
                option.Selected = !option.Selected;
                angular.forEach(vm.assets,
                    function (value, key, obj) {
                        value.Selected = option.Selected;
                    });
            } else {
                option.Selected = !option.Selected;
                vm.assets[0].Selected = false;
            }
           
        }

        function sort(order) {
            vm.Model.SortBy = order;
            vm.Model.SortDirection = vm.Model.SortDirection == 'ASC' ? 'DESC' : 'ASC';
            vm.Model.PageNum = 1;
            search(1);
        }

        function selectPage(pageNum) {
            vm.Model.PageNum = pageNum;
            search(0);
        }      

        function reset() {
            $window.location.reload();
        }

        function exportToExcel() {
            //create header
            //var header = '"Name: ' + vm.Model.Name + '"\r\n' + '"Services: ' + vm.selectedServiceNames + '"\r\n\r\n';
            vm.Model.Isexport = true;
            cusoApi.search.cu(vm.Model).then(function (data) {
                vm.FullResultList = data.Data.FullResultList;
                excelExport.exportCuSearchToExcel(JSON.stringify(vm.FullResultList));
            }, function (error) {
            });
        }
    }
})();