(function () {
    'use strict';
    var app = angular.module("cusoAnalyzer");
    app.factory('cusoSession', ['cusoApi', '$cookies', function (cusoApi, $cookies) {
        var trackId = $cookies.get("cusoTrackId");
        var sessionEndTime = null;

        return {
            sessionEndTime: function () {
                return this.sessionEndTime;
            },
            getTrackId: function () {
                return $cookies.get("cusoTrackId");
                //return trackId;
            },
            getIsAdmin: function () {
                return $cookies.get("isAdmin");
                //return trackId;
            },

            login: function (credential) {
                //return 16;
                return cusoApi.track.login(credential).then(function (data) {
                    if (data.Data === 0) return data.Data; //invalid login

                    var momentOfTime = new Date(); // just for example, can be any other time
                    var myTimeSpan = 30 * 60 * 1000; // 31 minutes in milliseconds
                    var domain = credential.Email.substring(credential.Email.lastIndexOf("@") + 1);
                    var isAdmin = domain == 'callahan.com' && credential.Passcode == 41985 ? true : false;

                    momentOfTime.setTime(momentOfTime.getTime() + myTimeSpan);
                    $cookies.put("cusoTrackId", data.Data, { expires: momentOfTime });
                    $cookies.put("isAdmin", isAdmin, { expires: momentOfTime });
                    //$cookies.put("emailDomain", domain, { expires: momentOfTime });

                    return data.Data;
                }, function (error) {
                    return 0;
                });
            },
            validateSession: function () {
                var cusoTrackId = $cookies.get("cusoTrackId");
                if (angular.isundefined(cusoTrackId)) return 0;

                var momentOfTime = new Date(); // just for example, can be any other time
                var myTimeSpan = 30 * 60 * 1000; // 30 minutes in milliseconds
                momentOfTime.setTime(momentOfTime.getTime() + myTimeSpan);
                $cookies.put("cusoTrackId", cusoTrackId, { expires: myTimeSpan });
                return cusoTrackId;
            },
            logout: function (id) {
                return cusoApi.track.logout(id).then(function () {
                    var momentOfTime = new Date(); // just for example, can be any other time
                    var myTimeSpan = 31 * 60 * 1000; // 31 minutes in milliseconds
                    momentOfTime.setTime(momentOfTime.getTime() - myTimeSpan);
                    $cookies.put("cusoTrackId", id, { expires: momentOfTime });
                }, function (error) {
                    return 0;
                });
            }
        };
    }]);
})();