
(function () {
    'use strict';
    var app = angular.module("cusoAnalyzer");
    app.directive("verifyLoginDirective", function () {
        return {
            templateUrl: 'verify/login.html',
            controller: 'VerifyLoginCtrl',
            controllerAs: 'vm'

        };
    });
    angular.module('cusoAnalyzer')
        .controller('VerifyLoginCtrl', VerifyLoginCtrl);

    VerifyLoginCtrl.$inject = ['verificationSession', '$location'];

    function VerifyLoginCtrl(verificationSession, $location) {

        var vm = this;
        vm.login = login;

        function login() {
            verificationSession.login(vm.credential).then(function (data) {
                vm.user = data;
                if (vm.user.CdsId === 0) {
                    vm.ShowError = true;
                    return;
                }
                //var cdsId = verificationSession.getCdsId();
                $location.path('verify/' + vm.user.CdsId + '/edit');
            }, function (error) {
                return 0;
            });
            //$location.path('verify/85191/edit');
        }

    }
})();