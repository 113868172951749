(function () {
    'use strict';
    var app = angular.module("cusoAnalyzer");
    app.factory('cuSearchCriteria', ['cusoSession', function ( cusoSession) {
        var searchModel =
       {
           State: '',
           ServiceIds: [],
           Name: '',
           Charter: '',
           Assets: [],
           TrackId: cusoSession.getTrackId(),
           SortBy: 'Name',
           SortDirection: 'ASC',
           PageNum: 1,
           Isexport: 0
       };
        var newSearch = true;
        function set(data) {
            searchModel = data;
            newSearch = false;
        }
        function get() {
            searchModel.TrackId = cusoSession.getTrackId();
            return searchModel;
        }
        function resetSearch() {
            newSearch = true;
            searchModel.State = '';
            searchModel.ServiceIds = [];
            searchModel.Name = '';
            searchModel.Charter = '';
            searchModel.Assets = [];
            searchModel.ShowClients = '',
            searchModel.SortBy = 'Name';
            searchModel.SortDirection = 'ASC';
            searchModel.PageNum = 1;
            searchModel.Isexport = 0;
        }
        function isNewSearch() {
            return newSearch;
        }
        return {
            set: set,
            get: get,
            resetSearch: resetSearch,
            isNewSearch: isNewSearch
        };
    }]);
})();