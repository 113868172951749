
(function () {
    'use strict';
    var app = angular.module("cusoAnalyzer");
    app.directive("cuProfileDirective", function () {
        return {
            templateUrl: 'cuProfile.html',
            controller: 'CuProfileCtrl',
            controllerAs: 'vm'

        };
    });
    angular.module('cusoAnalyzer')
        .controller('CuProfileCtrl', CuProfileCtrl);

    CuProfileCtrl.$inject = ['$location', 'cusoApi', 'cusoSession', '$stateParams', 'excelExport'];

    function CuProfileCtrl($location, cusoApi, cusoSession, $stateParams, excelExport) {

        var vm = this;
        vm.cdsId = parseInt($stateParams.cdsid);
        vm.TrackId = cusoSession.getTrackId();
        vm.init = init;
        vm.sort = sort;
        vm.exportToExcel = exportToExcel;

        vm.AffiliateListModel = {
            SortBy: 'Name',
            SortOrder: 'ASC',
            PageNumber: 0
        };

        init();

        function init() {
            //valid session?
            if (vm.TrackId == undefined) {
                $location.path('/login');
            }

            //cusoSession.validateSession().then(function(data) {
            //        if (data === 0) { // not valid session
            //            $location.path("logout");
            //        } else {
            //            vm.trackId = data;
            //        }
            //    },
            //    function(error) {
            //    });

            cusoApi.cu.profile(vm.cdsId, 0).then(function (data) {
                if (angular.isUndefined(data.Data.Name)) {
                    // error            
                }
                else
                    vm.data = data.Data;
            }, function (error) {
                // error handling
            });

            cusoApi.cu.searchAndAnalyze(vm.cdsId, 0).then(function (data) {
                //vm.Financial = data.Data.data;
                vm.Financial = {
                    Assets: data.Data.data[0][112],
                    Loans: data.Data.data[0][113],
                    Investments: data.Data.data[0][144],
                    Capital: data.Data.data[0][143],
                    Members: data.Data.data[0][121],
                    Employees: data.Data.data[0][58],
                    ShareGrowth: data.Data.data[0][4] * 100,
                    LoanGrowth: data.Data.data[0][5] * 100,
                    Roa: data.Data.data[0][117] * 100, //169? -- no matching #
                    NumberOfBranches: data.Data.data[0][82]
                };
            }, function (error) {
                // error handling
            });

            //get the cycle name
            cusoApi.cu.byCdsId(vm.cdsId).then(function (data) {
                vm.cyclename = data.Data.CycleName;


            }, function (error) {
            });
            populateAffiliateList();
            populateUtilizedCusoList();

        }

        function populateAffiliateList() {
            cusoApi.cu.affiliates(vm.cdsId, vm.AffiliateListModel).then(function (data) {
                vm.affiliates = data.Data;
                vm.CusoCount = data.Data.length;
            }, function (error) {
            });
        }

        function populateUtilizedCusoList() {
            cusoApi.cu.utilizedCusos(vm.cdsId).then(function (data) {
                vm.utilizedCusos = data;
                vm.UtilizedCusoCount = data.length;
            }, function (error) {
            });
        }
        function sort(field) {
            if (vm.AffiliateListModel.SortBy === field) {
                vm.AffiliateListModel.SortOrder = vm.AffiliateListModel.SortOrder === 'ASC' ? 'DESC' : 'ASC';
            } else {
                vm.AffiliateListModel.SortBy = field;
                vm.AffiliateListModel.SortOrder = 'ASC';
            }
            populateAffiliateList();
        }

        function exportToExcel() {
            excelExport.exportCuProfileToExcel("Name,Address,Branches,Phone,Fax,Charter Number,URL", JSON.stringify(vm.data), 
                "Assets,Loans,Investments,Capital,Members,Employees,12-Month Share Growth,12-Month Loan Growth,ROA",
                JSON.stringify(vm.Financial), "CUSO,Investments ($),Loans ($),Cash Outlay ($)",
                JSON.stringify(vm.affiliates), "CUSO", JSON.stringify(vm.utilizedCusos), vm.data.Name);
        }
    }
})();