(function () {
    'use strict';

    angular.module('cusoAnalyzer').service('cusoApi', ['Restangular', function (Restangular) {
        //var RestangularWithCache = function () {
        //    return Restangular.withConfig(function (RestangularConfigurer) {
        //        RestangularConfigurer.setDefaultHttpFields({ cache: true, useXDomain: true });
        //    });
        //};
        return {
            track: {
                login: function (param) {
                    return Restangular.one('Cuso').one('Tracking').one("Login").customPOST(param);
                },
                logout: function (id) {
                    return Restangular.one('Cuso').one('Tracking', id).one("Logout").get();
                },
                verification: {
                    login: function(param) {
                        return Restangular.one('Cuso').one('Tracking').one("VerifyLogin").customPOST(param);
                    },
                    logout: function (param) {
                        return Restangular.one('Cuso').one('Tracking').one("VerifyLogout").customPOST(param);
                    }
                }
            },
            cu: {
                byCdsId: function (id) {
                    return Restangular.one('Cuso').one("CreditUnion", id).get();
                },
                affiliates: function (id, criteria) {
                    return Restangular.one('Cuso').one("CreditUnion", id).one('CUSOs').get(criteria);
                },
                byName: function (criteria) {
                    return Restangular.one('Cuso').one("CreditUnion").one("ByName").get(criteria);
                },
                utilizedCusos: function (id) {
                    return Restangular.one('Cuso').one("CreditUnion", id).getList('UtilizedCUSOs');
                },
                profile: function (id, memberId) {
                    return Restangular.one('CreditUnion', id).one('CompanyProfile', memberId).get();
                },
                searchAndAnalyze: function (id, memberId) {
                    return Restangular.one('CreditUnion', id).one('SearchAndAnalyze', memberId).get();
                }
            },
            cuso: {
                byCdsId: function (id) {
                    return Restangular.one('Cuso', id).get();
                },
                passcode: function (id) {
                    return Restangular.one('Cuso', id).one('Passcode').get();
                },
                activatePasscode: function (id) {
                    return Restangular.one('Cuso', id).one('ActivatePasscode').get();
                },
                generatePasscode: function (id) {
                    return Restangular.one('Cuso', id).one('GeneratePasscode').get();
                },
                addUpdateCuso: function (model) {
                    return Restangular.one('Cuso').one('AddUpdateCuso').customPOST(model);
                },
                owners: function (id, criteria) {
                    return Restangular.one('Cuso', id).one('Owners').get(criteria);
                },
                clients: function (id) {
                    return Restangular.one('Cuso', id).getList('Clients');
                },
                update: function (model) {
                    return Restangular.one('Cuso').customPOST(model);
                },
                updateOwner: function (model) {
                    return Restangular.one('Cuso').one('UpdateOwner').customPOST(model);
                },
                insertOwner: function (model) {
                    return Restangular.one('Cuso').one('InsertOwner').customPOST(model);
                    //return Restangular.one('Cuso').one('InsertOwner').customPOST(model);
                },
                deleteOwner: function (id, cusoCdsId) {
                    //return Restangular.one('Cuso', id).one('DeleteOwner', cusoCdsId).customDELETE();
                    return Restangular.one('Cuso', id).one('DeleteOwner', cusoCdsId).get();
                },
                insertClient: function (model) {
                    return Restangular.one('Cuso').one('InsertClient').customPOST(model);
                    //return Restangular.one('Cuso').one('InsertClient').customPOST(model);
                },
                deleteClient: function (id, cusoCdsId) {
                    return Restangular.one('Cuso', id).one('DeleteClient', cusoCdsId).get();
                    //return Restangular.one('Cuso', id).one('DeleteClient', cusoCdsId).get();
                },
                byName: function (criteria) {
                    return Restangular.one('Cuso').one("ByName").get(criteria);
                }
            },
            selection: {
                states: function () {
                    return Restangular.one('Selection').getList("UsStates");
                }
            },
            search: {
                cuso: function (criteria) {
                    return Restangular.one('Cuso').one("Search").get(criteria);
                },
                cu: function (criteria) {
                    return Restangular.one('Cuso').one("CreditUnion").one("Search").get(criteria);
                }
            },
            service: {
                // below are APIs for logged in user
                servicelist: function () {
                    return Restangular.one('Cuso').one('Service').getList('All');
                },
                servicesByCuso: function (id) {
                    return Restangular.one('Cuso').one('Service', id).getList('ByCuso');
                },
                withPreSelection: function (id) {
                    return Restangular.one('Cuso').one('Service', id).getList('ServiceWithPreSelection');
                },
                save: function (id, data) {
                    return Restangular.one('Cuso').one('Service', id).one('Save').customPOST(data);
                }
            },

            error: {
                insert: function (errorParam) {
                    return Restangular.one('Error', id).one("Insert", memberId).customPOST(errorParam);
                }
            }
        }
    }]);
})();