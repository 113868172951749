(function () {
    'use strict';
    var app = angular.module("cusoAnalyzer");
    app.directive("editRelationDirective", function () {
        return {
            templateUrl: 'verify/relation.html',
            controller: 'RelationCtrl',
            controllerAs: 'vm'

        };
    });
    angular.module('cusoAnalyzer')
        .controller('RelationCtrl', RelationCtrl);

    RelationCtrl.$inject = ['cusoApi', 'cusoVerifyApi', 'verificationSession', '$stateParams', '$location'];

    function RelationCtrl(cusoApi, cusoVerifyApi, verificationSession, $stateParams, $location) {
        var vm = this;
        vm.user = verificationSession.getUser();
        vm.cdsId = parseInt($stateParams.cdsid);
        vm.redirect = redirect;
        
        /* Owner */
        vm.newOwner = {
            CusoCdsId: vm.cdsId,
            CuCdsId: 0,
            //TotalInvestments: 0.00,
            //TotalAmountLoaned: 0.00,
            //TotalCashOutlay: 0.00,
            IsActive: true
        }
        vm.OwnerListModel = {
            SortBy: 'Name',
            SortOrder: 'ASC',
            PageNumber: 0
        };
        vm.addOwner = addOwner;
        vm.deleteOwner = deleteOwner;

        /* Client */
        vm.newClient = {
            CusoCdsId: vm.cdsId,
            CuCdsId: 0,
            IsActive: true
        };

        vm.selectedCU = {
            CusoCdsId: vm.cdsId,
            CuCdsId: 0,
            IsActive: true
        }

        vm.addClient = addClient;
        vm.deleteClient = deleteClient;

        /* Search */
        vm.SelectionList = [];
        vm.moduleType = 'cu';
        vm.exclusionListName = {
            Client: 'Client',
            Owner: 'Owner'
        };

        init();
        function init() {
            //check session
            vm.sessionId = verificationSession.getTrackId();
            if (vm.sessionId == undefined) {
                $location.path('/verify/login');
            }

            cusoApi.cuso.owners(vm.cdsId, vm.OwnerListModel).then(function (data) {
                vm.owners = data.Data;
                console.log(vm.owners);
            }, function (error) {
            });

            cusoApi.cuso.clients(vm.cdsId).then(function (data) {
                vm.clients = data;
                console.log(vm.clients);
            }, function (error) {
            });
        }

        /* Owner */
        function addOwner() {
            if (angular.isUndefined(vm.CuNameOwner) || vm.CuNameOwner.length < 3 || vm.SelectionList.length < 1) return;
            var selectedItem = vm.SelectionList.find(function (item) {
                return item.ItemName == vm.CuNameOwner;
            });
            vm.selectedCU.CuCdsId = selectedItem.ItemId;

            cusoVerifyApi.cuso.insertOwner(vm.selectedCU).then(function () { })
                .then(function () {
                    cusoApi.cuso.insertOwner(vm.selectedCU).then(function () {
                        var newOwner = {
                            ItemId: vm.selectedCU.CuCdsId,
                            ItemName: vm.CuNameOwner//.split('(')[0]
                        };
                        vm.owners.push(newOwner);
                        vm.selectedCU.CuCdsId = 0;
                        vm.CuNameOwner = '';
                    });
                });

            //angular.forEach(vm.SelectionList, function (value, key) {
            //    if (value.ItemName === vm.CuNameOwner)
            //        vm.newOwner.CuCdsId = value.ItemId;
            //});
            //cusoVerifyApi.cuso.insertOwner(vm.newOwner).then(function () { })
            // .then(function () {
            //     cusoApi.cuso.insertOwner(vm.newOwner).then(function () {
            //     });
            // });

            //vm.newOwner = {
            //    CuCdsId: vm.newOwner.CuCdsId,
            //    Name: vm.CuNameOwner.split('(')[0],
            //    //TotalInvestments: vm.newOwner.TotalInvestments,
            //    //TotalAmountLoaned: vm.newOwner.TotalAmountLoaned,
            //    //TotalCashOutlay: vm.newOwner.TotalCashOutlay,
            //    CusoCdsId: vm.newOwner.CusoCdsId
            //};
            //vm.owners.push(vm.newOwner);
            //vm.newOwner = {
            //    CusoCdsId: vm.cdsId,
            //    CuCdsId: 0,
            //    //TotalInvestments: 0.00,
            //    //TotalAmountLoaned: 0.00,
            //    //TotalCashOutlay: 0.00,
            //    IsActive: true
            //}
            //vm.CuNameOwner = '';
        }

        function deleteOwner(model) {
            cusoVerifyApi.cuso.deleteOwner(model.ItemId, vm.cdsId).then(function () {
                var index = vm.owners.indexOf(model);
                vm.owners.splice(index, 1);
            }).then(function () {
                cusoApi.cuso.deleteOwner(model.ItemId, vm.cdsId).then(function () {
                });
            });
        }

        /* Client */
        function addClient() {
            if (angular.isUndefined(vm.CuNameClient) || vm.CuNameClient.length < 3 || vm.SelectionList.length < 1) return;
            var selectedItem = vm.SelectionList.find(function (item) {
                return item.ItemName == vm.CuNameClient;
            });
            vm.selectedCU.CuCdsId = selectedItem.ItemId;

            cusoVerifyApi.cuso.insertClient(vm.selectedCU).then(function () { })
                .then(function () {
                    cusoApi.cuso.insertClient(vm.selectedCU).then(function () {
                        var newClient = {
                            ItemId: vm.selectedCU.CuCdsId,
                            ItemName: vm.CuNameClient//.split('(')[0]
                        };
                        vm.clients.push(newClient);
                        vm.selectedCU.CuCdsId = 0;
                        vm.CuNameClient = '';
                    });
            });
        }

        function deleteClient(model) {
            cusoVerifyApi.cuso.deleteClient(model.ItemId, vm.cdsId).then(function () {
                var index = vm.clients.indexOf(model);
                vm.clients.splice(index, 1);
            }).then(function () {
                cusoApi.cuso.deleteClient(model.ItemId, vm.cdsId).then(function () {
                });
            });
        }

        function redirect(direction) {
            if (direction === 'prev') $location.path('verify/' + vm.cdsId + '/edit');
            else $location.path('verify/' + vm.cdsId + '/confirm');
        }
    }
})();