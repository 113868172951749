(function () {
    'use strict';
    var app = angular.module("cusoAnalyzer");
    app.factory('verificationSession', ['cusoVerifyApi', '$cookies', function (cusoVerifyApi, $cookies) {
        var verificationTrackId = $cookies.get("verificationTrackId");
        var sessionEndTime = null;
        var verificationUser = {};

        return {
            sessionEndTime: function () {
                return this.sessionEndTime;
            },
            getTrackId: function () {
                return $cookies.get("verificationTrackId");
                //return trackId;
            },
            getUser: function () {
                return verificationUser;
                //return trackId;
            },
            login: function (credential) {
                //return 16;
                return cusoVerifyApi.track.login(credential).then(function (data) {
                    if (data.Data.SessionId === undefined) return "0"; //invalid login

                    verificationUser = data.Data;
                    var momentOfTime = new Date(); // just for example, can be any other time
                    var myTimeSpan = 30 * 60 * 1000; // 31 minutes in milliseconds
                    momentOfTime.setTime(momentOfTime.getTime() + myTimeSpan);
                    $cookies.put("verificationTrackId", data.Data.SessionId, { expires: momentOfTime });
                    return data.Data;
                }, function (error) {
                    return 0;
                });
            },
            validateSession: function () {
                var verificationTrackId = $cookies.get("verificationTrackId");
                if (angular.isundefined(verificationTrackId)) return 0;

                var momentOfTime = new Date(); // just for example, can be any other time
                var myTimeSpan = 30 * 60 * 1000; // 30 minutes in milliseconds
                momentOfTime.setTime(momentOfTime.getTime() + myTimeSpan);
                $cookies.put("verificationTrackId", verificationTrackId, { expires: myTimeSpan });
                return verificationTrackId;
            },
            logout: function (id) {
                var session = {SessionId: id}
                return cusoVerifyApi.track.logout(session).then(function () {
                    var momentOfTime = new Date(); // just for example, can be any other time
                    var myTimeSpan = 31 * 60 * 1000; // 31 minutes in milliseconds
                    momentOfTime.setTime(momentOfTime.getTime() - myTimeSpan);
                    $cookies.put("verificationTrackId", id, { expires: momentOfTime });
                }, function (error) {
                    return 0;
                });
            }
        };
    }]);
})();