(function () {
    'use strict';
    var app = angular.module("cusoAnalyzer");
    app.directive("confirmDirective", function () {
        return {
            templateUrl: 'verify/confirm.html',
            controller: 'ConfirmCtrl',
            controllerAs: 'vm'

        };
    });
    angular.module('cusoAnalyzer')
        .controller('ConfirmCtrl', ConfirmCtrl);

    ConfirmCtrl.$inject = ['cusoVerifyApi', 'verificationSession', '$stateParams', '$location'];

    function ConfirmCtrl(cusoVerifyApi, verificationSession, $stateParams, $location) {
        var vm = this;
        vm.user = verificationSession.getUser();
        vm.cdsId = parseInt($stateParams.cdsid);
        init();
        function init() {
            //check session
            vm.sessionId = verificationSession.getTrackId();
            if (vm.sessionId == undefined) {
                $location.path('/verify/login');
            }

            var trackId = verificationSession.getTrackId();
            verificationSession.logout(vm.sessionId).then(function () { });

        }
    }
})();