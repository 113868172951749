
(function () {
    'use strict';
    var app = angular.module("cusoAnalyzer");
    app.directive("aboutDirective", function () {
        return {
            templateUrl: 'about.html',
            controller: 'AboutCtrl',
            controllerAs: 'vm'

        };
    });
    angular.module('cusoAnalyzer')
        .controller('AboutCtrl', AboutCtrl);

    AboutCtrl.$inject = ['$location', 'cusoSession'];

    function AboutCtrl($location, cusoSession) {

        var vm = this;
        vm.TrackId = cusoSession.getTrackId(),
        init();

        function init() {
            //valid session?
            if (vm.TrackId == undefined) {
                $location.path('/login');
            }
        }
    }
})();