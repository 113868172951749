(function () {
    'use strict';
    var app = angular.module("cusoAnalyzer");
    app.factory('searchCriteria', ['cusoSession', function (cusoSession) {
        var searchModel =
       {
           State: '',
           ServiceIds: [],
           Name: '',
           Charter: '',
           Assets: [],
           IsWhollyOwned: '',
           OnlyNacuso: '',
           NeedInvestor: false,
           TrackId: cusoSession.getTrackId(),
           SortBy: 'Name',
           SortDirection: 'ASC',
           PageNum: 1,
           Isexport: false
       };
        var newSearch = true;
        function set(data) {
            searchModel = data;
            newSearch = false;
        }
        function get() {
            searchModel.TrackId = cusoSession.getTrackId();
            return searchModel;
        }
        function resetSearch() {
            newSearch = true;
            searchModel.State = '';
            searchModel.ServiceIds = [];
            searchModel.Name = '';
            searchModel.Charter = '';
            searchModel.Assets = [];
            searchModel.IsWhollyOwned = '';
            searchModel.OnlyNacuso = '';
            searchModel.NeedInvestor = false;
            searchModel.SortBy = 'Name';
            searchModel.SortDirection = 'ASC';
            searchModel.PageNum = 1;
            searchModel.Isexport = false;
            return searchModel;
        }
        function sort(field) {
            searchModel.SortBy = field;
            searchModel.SortDirection = searchModel.SortDirection == 'ASC' ? 'DESC' : 'ASC';
            searchModel.PageNum = 1;
        }
        function isNewSearch() {
            return newSearch;
        }
        return {
            set: set,
            get: get,
            resetSearch: resetSearch,
            isNewSearch: isNewSearch,
            sort: sort

        };
    }]);
})();