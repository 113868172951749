
(function () {
    'use strict';
    var app = angular.module("cusoAnalyzer");
    app.directive("serviceTree", function () {
        return {
            templateUrl: 'utility/serviceTree.html',
            controller: 'ServiceCtrl',
            controllerAs: 'vm',
            bindToController: true,
            scope: {
                selectedServices: "=",
                preSelected: "=",
                selectedServiceName: "=",
                selectedServiceNames: "=",
                initial: "="
            }

        };
    });
    angular.module('cusoAnalyzer')
        .controller('ServiceCtrl', ServiceCtrl);

    ServiceCtrl.$inject = ['$location', 'cusoApi',  'cuSearchCriteria'];

    function ServiceCtrl($location, cusoApi,  cuSearchCriteria) {

        var vm = this;
        vm.checkAllService = checkAllService;
        vm.checkService = checkService;
        vm.getSelectedServices = getSelectedServices;
        vm.allServices = true;
        vm.showServiceError = false;
        init();

        function init() {
            //service list
            if (vm.initial) {
                cusoApi.service.servicelist().then(function(data) {
                        vm.services = data;
                        if (vm.preSelected > 0) { //service is pre-selected
                            angular.forEach(vm.services,
                                function(value, key, obj) {
                                    if (value.ServiceId === vm.preSelected) {
                                        checkService(value);
                                        vm.selectedServiceName = value.Name;
                                        vm.selectedServiceNames = value.Name;
                                    }
                                });

                        } else {
                            checkAllService();
                        }
                    },
                    function(error) {
                    });
                vm.initial = false;
            }
        }


        function checkAllService() {
            if (vm.allServices) {
                angular.forEach(vm.services,
                    function (value, key, obj) {
                        value.Selected = true;
                    });
            } else {
                angular.forEach(vm.services,
                    function (value, key, obj) {
                        value.Selected = false;
                    });
            }
            getSelectedServices();
        }

        function checkService(option) {
            if (option.ServiceId == option.ParentServiceId) {
                option.Selected = !option.Selected;
                //all the children whill be conformed
                angular.forEach(vm.services, function (value, key, obj) {
                    if (value.ParentServiceId == option.ServiceId)
                        value.Selected = option.Selected;
                });
            } else {
                option.Selected = !option.Selected;
            }
            //if (!option.Selected)
                vm.allServices = false;
            getSelectedServices();
        }

        function getSelectedServices() {
            vm.selectedServices = [];
            vm.selectedServiceNames = '';
            //get all checked services
            if (vm.allServices) { // all services selected
                angular.forEach(vm.services,
                    function (value, key, obj) {
                        if (value.ParentServiceId !== value.ServiceId) {
                            vm.selectedServices.push(value.ServiceId);
                            vm.selectedServiceNames += value.Name + ', ';
                        }
                    });
            } else { // individual service selected
                angular.forEach(vm.services,
                    function (value, key, obj) {
                        if ((value.ParentServiceId !== value.ServiceId) && value.Selected) {
                            vm.selectedServices.push(value.ServiceId);
                            vm.selectedServiceNames += value.Name + ', ';
                        }
                    });
            }
            if (vm.selectedServices.length < 1) {
                vm.showServiceError = true;
            }
            vm.selectedServiceNames = vm.selectedServiceNames.substring(0, (vm.selectedServiceNames.length - 2));
        }

        
    }
})();