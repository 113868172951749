(function () {
    'use strict';

    var app = angular.module("cusoAnalyzer");
    app.directive("popupDirective", function () {
        return {
            templateUrl: 'popupDirective.html',
            controller: 'PopupCtrl',
            controllerAs: 'vm'

        };
    });

    angular.module('cusoAnalyzer')
        .controller('PopupCtrl', PopupCtrl);

    PopupCtrl.$inject = ['$scope', '$uibModalInstance', 'PopupMsg', 'PopupTitle'];
    function PopupCtrl($scope, $uibModalInstance, PopupMsg, PopupTitle) {
        var vm = this;
        vm.PopupMsg = PopupMsg;
        vm.PopupTitle = PopupTitle;
        vm.cancel = cancel;

        function cancel() {
            $uibModalInstance.close();
        };
    };
})();