
(function () {
    'use strict';
    var app = angular.module("cusoAnalyzer");
    app.directive("cusoProfileDirective", function () {
        return {
            templateUrl: 'cusoProfile.html',
            controller: 'CusoProfileCtrl',
            controllerAs: 'vm'

        };
    });
    angular.module('cusoAnalyzer')
        .controller('CusoProfileCtrl', CusoProfileCtrl);

    CusoProfileCtrl.$inject = ['cusoApi', 'cusoSession', '$stateParams', '$location', '$window', 'excelExport'];

    function CusoProfileCtrl(cusoApi, cusoSession, $stateParams, $location, $window, excelExport) {
    
        var vm = this;
        vm.init = init;
        vm.sort = sort;
        vm.exportToExcel = exportToExcel;
        vm.cdsId = parseInt($stateParams.cdsid);
        vm.TrackId = cusoSession.getTrackId();
        vm.adminFlag = cusoSession.getIsAdmin() === 'true' ? true : false;
        vm.OwnerListModel = {
            SortBy: 'Name',
            SortOrder: 'ASC',
            PageNumber: 0
        };
        vm.linkVerification = linkVerification;
        vm.passCode = undefined;
        vm.passCodeStatus = undefined;
        vm.getPasscode = getPasscode;
        vm.activatePasscode = activatePasscode;
        vm.generatePasscode = generatePasscode;
        
        init();

        function init() {
            //valid session?
            if (vm.TrackId == undefined) {
                $location.path('/login');
            }

            cusoApi.cuso.byCdsId(vm.cdsId).then(function (data) {
                vm.data = data.Data;
                vm.ResultStringGeneral = JSON.stringify(vm.data);
            }, function (error) {
            });
            cusoApi.service.servicesByCuso(vm.cdsId).then(function (data) {
                vm.services = data;
                vm.ResultStringService = JSON.stringify(vm.services);
            }, function (error) {
            });
            
            cusoApi.cuso.clients(vm.cdsId).then(function (data) {
                vm.clients = data;
                vm.ClientCount = data.length;
                vm.ResultStringClients = JSON.stringify(vm.clients);
            }, function (error) {
            });
            populateOwnerList();
            getPasscode();
        }

        function populateOwnerList() {
            cusoApi.cuso.owners(vm.cdsId, vm.OwnerListModel).then(function (data) {
                vm.owners = data.Data;
                vm.OwnerCount = data.Data.length;
                vm.ResultStringOwners = JSON.stringify(vm.owners);
            }, function (error) {
            });
        }

        //vm.filterCriteria = {
        //    CompanyName: '',
        //    //Address: '',
        //    City: '',
        //    StateId: '',
        //    UserCount: -1,
        //    AdminCount: -1,
        //    IsAsc: false,
        //    SortByField: 'CompanyName'//,
        //    //pageSize: $scope.pageSizes[0].value
        //};

        function sort(field) {
            if (vm.OwnerListModel.SortBy === field) {
                vm.OwnerListModel.SortOrder = vm.OwnerListModel.SortOrder === 'ASC' ? 'DESC' : 'ASC';
            } else {
                vm.OwnerListModel.SortBy = field;
                vm.OwnerListModel.SortOrder = 'ASC';
            }
            populateOwnerList();
        }

        function linkVerification() {
            $window.open('#/verify/login');
        }
        function exportToExcel() {
            excelExport.exportCusoProfileToExcel("Name,Address,City,State,Zip,Website,Wholly Owned?,Non-CU Owners?",
                vm.ResultStringGeneral,
                vm.ResultStringService,
                //"Credit Union,Investments ($) ,Loans ($),Cash Outlay ($)",
                "Name",
                vm.ResultStringOwners,
                "Name",
                vm.ResultStringClients, vm.data.Name);
        }

        function getPasscode() {
            cusoApi.cuso.passcode(vm.cdsId).then(function (data) {
                if (!angular.isUndefined(data.Data) && data.Data != null) {
                    vm.passCode = data.Data.Passcode;
                    vm.passCodeStatus = data.Data.IsActive ? vm.passCode : 'Inactive';
                } else {
                    vm.passCodeStatus = 'No record found, please generate passcode';
                }
            }, function (error) {
                console.log(error);
            });
        }

        function activatePasscode() {
            cusoApi.cuso.activatePasscode(vm.cdsId).then(function (data) {
                if (!angular.isUndefined(data.Data) && data.Data != null) {
                    vm.passCode = data.Data.Passcode;
                    vm.passCodeStatus = data.Data.IsActive ? vm.passCode : 'Inactive';
                } else {
                    vm.passCodeStatus = 'No record found, please generate passcode';
                }
            }, function (error) {
                console.log(error);
            });
        }

        function generatePasscode() {
            if (angular.isUndefined(vm.passCode)) {
                cusoApi.cuso.generatePasscode(vm.cdsId).then(function (data) {
                    debugger
                    vm.passCodeStatus = vm.passCode = data.Data.Passcode;
                }, function (error) {
                    console.log(error);
                });
            }
            else {
                alert('Passcode exists');
            }
        }
    }
})();