
(function () {
    'use strict';
    var app = angular.module("cusoAnalyzer");
    app.directive("searchDirective", function () {
        return {
            templateUrl: 'search.html',
            controller: 'SearchCtrl',
            controllerAs: 'vm'

        };
    });
    angular.module('cusoAnalyzer')
        .controller('SearchCtrl', SearchCtrl);

    SearchCtrl.$inject = ['$scope', '$location', 'cusoApi', 'cusoVerifyApi', 'cusoSession', '$stateParams', 'searchCriteria', '$window', 'excelExport', '$uibModal'];

    function SearchCtrl($scope, $location, cusoApi, cusoVerifyApi, cusoSession, $stateParams, searchCriteria, $window, excelExport, $uibModal) {

        var vm = this;
        vm.serviceid = $stateParams.id ? parseInt($stateParams.id) : 0;
        vm.init = init;
        vm.cancel = cancel;
        vm.addUpdateCuso = addUpdateCuso;
        vm.showCusoForm = showCusoForm;
        vm.submitFirstStep;

        vm.services = {};
        vm.checkService = checkService;
        vm.clearServices = clearServices;

        vm.isCuso;
        vm.clearForm = clearForm;
        vm.search = search;
        vm.sort = sort;
        vm.selectPage = selectPage;
        vm.reset = reset;
        vm.generatePasscode = generatePasscode;
        vm.adminFlag = cusoSession.getIsAdmin() === 'true' ? true : false;

        vm.showError = false;
        vm.states = [];
        vm.selectedServiceName = '';
        vm.allServices = true;
        vm.Model = searchCriteria.get();
        vm.criteria = {};
        vm.initial = true;
        vm.moduleType = 'cuso';
        vm.exclusionListName = '';
        vm.SelectionList = [];
        vm.ServiceIds = [];
        vm.exportToExcel = exportToExcel;
        vm.selectedServiceNames = '';
        vm.Model.OnlyNacuso = 2;
        vm.cusoFormFlag = false;
        //vm.popup = {
        //    PopupMsg: '',
        //    PopupTitle: ''
        //}
        vm.Cuso = {
            CdsId: undefined,
            //EinId: '',
            //RegistryId: '',
            //Name: '',
            //Address: '',
            //City: '',
            //State: '',
            //Zipcode: '',
            //IsWhollyOwned: '',
            //ManagerName: '',
            //Phone: '',
            //Website: '',
            //HasNonCuOwner: '',
            //LastUpdated: Date.now,
            IsActive: true,
            //TotalInvestment: '',
            //TotalLoans: '',
            //NumEmployees: '',
            //CycleName: '',
            //NumOwners: '',
            //NumMemberClients: '',
            //NeedInvestor: '',
            //IsNacuso: ''
        }

        init();

        function init() {
            //valid session?
            if (vm.Model.TrackId == undefined) {
                $location.path('/login');
            }
            //state list
            cusoApi.selection.states().then(function (data) {
                vm.states = data;
            }, function (error) {
            });

            cusoApi.service.servicelist().then(function (data) {
                vm.services = data;
            });

            //service list
            if (vm.serviceid > 0) {
                vm.Model = searchCriteria.resetSearch();
                vm.Model.ServiceIds.push(vm.serviceid);
                //vm.Model = searchCriteria.resetSearch();
                search(1, 0);
            } else if (!searchCriteria.isNewSearch()) {
                search(0, 0);
            }
        }

        function showModal(title, message) {
            var modalInstance = $uibModal.open({
                templateUrl: 'popup.html',
                controller: 'PopupCtrl',
                size: 'md',
                controllerAs: 'vm',
                resolve: {
                    PopupTitle: function () {
                        return title;
                    },
                    PopupMsg: function () {
                        return message;
                    },
                }
            });
            vm.title = vm.message = '';
        }

        function showCusoForm() {
            vm.cusoFormFlag = vm.Cuso.CdsId == undefined ? false : true;

            cusoApi.cuso.byCdsId(vm.Cuso.CdsId).then(function (data) {
                if(data.Data) vm.Cuso = data.Data;
                return vm.Cuso;
            }).then(function (cuso) {
                if (!cuso) return;
                cusoApi.service.servicesByCuso(cuso.CdsId).then(function (data) {

                    var selectedServices = data;
                    selectedServices.forEach(function (selectedService) {
                        vm.services.find(function (service) {
                            return service.ServiceId == selectedService.ServiceId;
                        }).Selected = true;
                    });
                }, function (error) {
                });
            });
        }

        function cancel() {
            vm.cusoFormFlag = false;
        }

        function clearForm() {
            vm.Cuso = null;
            vm.clearServices();
            vm.isCuso = undefined;
            vm.cancel();
        }

        function clearServices() {
            vm.ServiceIds = [];
            vm.services.forEach(function (option){
                option.Selected = false;
            });
        }

        function addUpdateCuso() {
            cusoApi.cuso.addUpdateCuso(vm.Cuso).then(function (data) {
                vm.newCuso = data.Data;
                return vm.newCuso;
            }, function (error) {
                console.error(error);
            })
            .then(function (cuso) {
                //save service list
                angular.forEach(vm.services,
                       function (value, key, obj) {
                           if ((value.ParentServiceId !== value.ServiceId) && value.Selected)
                               vm.ServiceIds.push(value.ServiceId);
                       });
                cusoApi.service.save(cuso.CdsId, vm.ServiceIds).then(function () { });
                cusoVerifyApi.service.save(cuso.CdsId, vm.ServiceIds).then(function () { });

                cusoApi.cuso.passcode(cuso.CdsId).then(function (data) {
                    if (!angular.isUndefined(data.Data) && data.Data != null) {
                        vm.passCode = data.Data.Passcode
                        return data.Data.Passcode;
                    }
                }, function (error) {
                    console.error(error);
                })
                .then(function (passCode) {
                    if (passCode) {
                        vm.passCode = passCode;
                        if (vm.isCuso) {
                            // required. passcode passed into Cuso Model. passcode retrieved from NacusoUser table in CusoAnalyzer
                            vm.Cuso.Passcode = passCode;
                            cusoVerifyApi.cuso.addUpdateCuso(vm.Cuso).then(function (data) {
                                vm.newCuso = data.Data;
                            }, function (error) {
                                console.error(error);
                            });
                        } else {
                            cusoVerifyApi.cuso.deactivateCuso(vm.Cuso.CdsId).then(function () {});
                        }
                    } else {
                        //console.log('Passcode does not exist');
                    }
                    var title = 'Cuso ' + cuso.Name + ' added/updated';
                    var message = 'Cds ID: ' + vm.Cuso.CdsId + ' Passcode: ' + vm.passCode;
                    showModal(title, message);
                    vm.clearForm();
                    //vm.cancel();
                });
            }, function (error) {
                console.error(error);
            });
        }

        function generatePasscode() {
            if (!angular.isUndefined(vm.Cuso.CdsId)) {
                cusoApi.cuso.generatePasscode(vm.Cuso.CdsId).then(function (data) {
                    var pass;
                    if (data.Data != null) {
                        pass = data.Data.Passcode;
                        //console.log('Nacuso User - Passcode ' + pass + ' Generated for ' + vm.Cuso.CdsId);
                        vm.title = 'Passcode Generated';
                        vm.message = 'Cds ID: ' + vm.Cuso.CdsId + '\nPasscode: ' + pass;
                    } else {
                        vm.title = "Cuso doesn't exist";
                        vm.message = "Please Add/Update Cuso first";
                    }
                    return pass;
                }, function (analyzerError) {
                    console.error(analyzerError);
                }).then(function (pass) {
                    if (vm.isCuso && pass) {
                        cusoVerifyApi.cuso.generatePasscode(vm.Cuso.CdsId, pass).then(function (data) {
                            if (data.Data != null) {
                                //console.log('Verification User - Passcode ' + pass + ' Generated for ' + vm.Cuso.CdsId);
                                vm.title = 'Passcode Generated';
                                vm.message = 'Cds ID: ' + vm.Cuso.CdsId + '\nPasscode: ' + pass;
                            } else {
                                vm.title = "Cuso doesn't exist in verfication database";
                                vm.message = "Please select Yes for 'Is it a CUSO?' and Add Cuso first";
                            }
                            showModal(vm.title, vm.message);
                        }, function (error) {
                            console.error(error);
                        });
                    } else {
                        showModal(vm.title, vm.message);
                    }

                }, function (verificationError) {
                    console.error(verificationError);
                });
            }
        }

        function checkService(option) {
            if (option.ServiceId === option.ParentServiceId) {
                option.Selected = !option.Selected;
                //all the children will conform
                angular.forEach(vm.services, function (value, key, obj) {
                    if (value.ParentServiceId == option.ServiceId)
                        value.Selected = option.Selected;
                });
            } else {
                option.Selected = !option.Selected;
            }
            if (option.Selected)
                vm.allServices = false;
        }

        function search(newSearch, srcBtnClick) {         
            if (newSearch)
                vm.Model.PageNum = 1;

            if (srcBtnClick) //the page was originally loaded with service id, but now the search criteria has changed from search button
                vm.serviceid = 0;

            if (vm.Model.Name != undefined)
                vm.Model.Name = vm.Model.Name.split(' (')[0];
            else
                vm.Model.Name = '';

            searchCriteria.set(vm.Model);
            if (vm.serviceid < 1 && vm.Model.ServiceIds.length < 1) {
                vm.showServiceError = true;
                return;
            }
            else vm.showServiceError = false;

            //if (vm.Model.OnlyNacuso === '') vm.Model.OnlyNacuso = 1;
            vm.Model.Isexport = false;
            cusoApi.search.cuso(vm.Model).then(function (data) {
                vm.ResultList = data.Data.ResultList;
                vm.PageNum = data.Data.PageNum;
                vm.TotalResultCount = data.Data.TotalResultCount;
                vm.ResultString = JSON.stringify(vm.ResultList);
                vm.ResultString = vm.ResultString.replace('|', ',');
            }, function (error) {
            });
        }

        function sort(order) {
            searchCriteria.sort(order);
            search(1, 0);
        }

        function selectPage(pageNum) {
            vm.Model.PageNum = pageNum;
            search(0, 0);
        }

        function reset() {
            $window.location.reload();
        }

        function exportToExcel() {
            //create header
            //var header = '"Name: ' + vm.Model.Name + '"\r\n' + '"Services: ' + vm.selectedServiceNames + '"\r\n\r\n';
            //excelExport.exportToExcel("|Name|State|Services|", vm.ResultString, "CUSOs", true, header);
            vm.Model.Isexport = true;
            cusoApi.search.cuso(vm.Model).then(function (data) {
                vm.FullResultList = data.Data.FullResultList;
                excelExport.exportCusoSearchToExcel("|Name|State|Services|", vm.FullResultList, "CUSO Search Results", true, "CUSO Search Results");
            }, function (error) {
            });

        }

    }
})();