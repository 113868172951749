(function () {
    'use strict';

    angular.module('cusoAnalyzer').service('cusoVerifyApi', ['Restangular', function (Restangular) {
        //var RestangularWithCache = function () {
        //    return Restangular.withConfig(function (RestangularConfigurer) {
        //        RestangularConfigurer.setDefaultHttpFields({ cache: true, useXDomain: true });
        //    });
        //};
        return {
            track: {

                login: function (param) {
                    return Restangular.one('Cuso').one('Verify').one("VerifyLogin").customPOST(param);
                },
                logout: function (param) {
                    return Restangular.one('Cuso').one('Verify').one("VerifyLogout").customPOST(param);
                }
            },
            //cu: {
            //    byCdsId: function (id) {
            //        return Restangular.one('Cuso').one("CreditUnion", id).get();
            //    },
            //    affiliates: function (id, criteria) {
            //        return Restangular.one('Cuso').one("CreditUnion", id).one('CUSOs').get(criteria);
            //    },
            //    byName: function (criteria) {
            //        return Restangular.one('Cuso').one("CreditUnion").one("ByName").get(criteria);
            //    },
            //    utilizedCusos: function (id) {
            //        return Restangular.one('Cuso').one("CreditUnion", id).getList('UtilizedCUSOs');
            //    }
            //},
            cuso: {
                byCdsId: function (id) {
                    return Restangular.one('Cuso').one('Verify', id).get();
                },
                passcode: function (id) {
                    return Restangular.one('Cuso').one('Verify', id).one('Passcode').get();
                },
                activatePasscode: function (id) {
                    return Restangular.one('Cuso').one('Verify', id).one('ActivatePasscode').get();
                },
                generatePasscode: function (id, pass) {
                    return Restangular.one('Cuso').one('Verify', id).one('GeneratePasscode', pass).get();
                },
                addCU: function (model) {
                    return Restangular.one('Cuso').one('Verify').one('addCU').customPOST(model);
                },
                addUpdateCuso: function (model) {
                    return Restangular.one('Cuso').one('Verify').one('AddUpdateCuso').customPOST(model);
                },
                deactivateCuso: function (id) {
                    //return Restangular.one('Cuso').one('Verify', id).one('ClientOut', cusoCdsId).customDELETE();
                    return Restangular.one('Cuso').one('Verify', id).one('DeactivateCuso').get();
                },
                owners: function (id, criteria) {
                    return Restangular.one('Cuso').one('Verify', id).one('Owners').get(criteria);
                },
                clients: function (id) {
                    return Restangular.one('Cuso').one('Verify', id).getList('Clients');
                },
                update: function (model) {
                    return Restangular.one('Cuso').one('Verify').customPOST(model);
                },
                updateOwner: function (model) {
                    return Restangular.one('Cuso').one('Verify').one('UpdateOwner').customPOST(model);
                },
                insertOwner: function (model) {
                    return Restangular.one('Cuso').one('Verify').one('InsertOwner').customPOST(model);
                },
                deleteOwner: function (id, cusoCdsId) {
                    //return Restangular.one('Cuso').one('Verify', id).one('DeleteOwner', cusoCdsId).customDELETE();
                    return Restangular.one('Cuso').one('Verify', id).one('DeleteOwner', cusoCdsId).get();
                },
                insertClient: function (model) {
                    return Restangular.one('Cuso').one('Verify').one('InsertClient').customPOST(model);
                },
                deleteClient: function (id, cusoCdsId) {
                    //return Restangular.one('Cuso').one('Verify', id).one('ClientOut', cusoCdsId).customDELETE();
                    return Restangular.one('Cuso').one('Verify', id).one('ClientOut', cusoCdsId).get();
                }
                //,
                //byName: function (criteria) {
                //    return Restangular.one('Cuso').one("ByName").get(criteria);
                //}
            },
            selection: {
                states: function () {
                    return Restangular.one('Selection').getList("UsStates");
                }
            },
            //search: {
            //    cuso: function (criteria) {
            //        return Restangular.one('Cuso').one("Search").get(criteria);
            //    },
            //    cu: function (criteria) {
            //        return Restangular.one('Cuso').one("CreditUnion").one("Search").get(criteria);
            //    }
            //},
            service: {
                // below are APIs for logged in user
                servicelist: function () {
                    return Restangular.one('Cuso').one('Verify').one('Service').getList('All');
                },
                servicesByCuso: function (id) {
                    return Restangular.one('Cuso').one('Verify').one('Service', id).getList('ByCuso');
                },
                withPreSelection: function (id) {
                    return Restangular.one('Cuso').one('Verify').one('Service', id).getList('ServiceWithPreSelection');
                },
                save: function (id, data) {
                    return Restangular.one('Cuso').one('Verify').one('Service', id).one('Save').customPOST(data);
                }
            },

            error: {
                insert: function (errorParam) {
                    return Restangular.one('Error', id).one("Insert", memberId).customPOST(errorParam);
                }
            }
        }
    }]);
})();