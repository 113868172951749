(function () {
    'use strict';
    // 
    // Here is how to define your module 
    // has dependent on mobile-angular-ui
    // 
    var app = angular.module('cusoAnalyzer', [
      'ngRoute',
      'restangular',
      'ngCookies',
      'ui.bootstrap',
      'ui.router',
      'angulartics',
        'angulartics.google.tagmanager'
    ]);

    app.run(['$rootScope', '$location', '$window',  function ($rootScope, $location, $window) {
        //window.$transform = $transform;

        //-------------- BEGIN GOOGLE ANALYTICS ------------------------
        // https://github.com/angulartics/angulartics-google-tag-manager/blob/master/readme.md 
        // http://angulartics.github.io/ 
        //$window.ga('create', 'UA-416148-26', 'auto');
        ga('create', 'UA-416148-26', 'auto');
        //ga('send', 'pageview');
        

        // track pageview on state change
        $rootScope.$on('$stateChangeSuccess', function (event) {
            $window.ga('send', 'pageview', $location.path());
        });
        //-------------- END GOOGLE ANALYTICS ------------------------
    }]);

    app.config(['$stateProvider', '$urlRouterProvider', 'RestangularProvider', function ($stateProvider, $urlRouterProvider, RestangularProvider) {
        //$analyticsProvider.virtualPageviews(false);
        //$analyticsProvider.settings.ga = {
        //    userId: myUserIdValue
        //};

        $urlRouterProvider.otherwise('/search');
        $stateProvider
            .state('login',
            {
                url: '/login',
                views: {
                    'mainView': {
                        template: '<login-directive></login-directive>'
                    },
                    'menuView': {
                        template: '<menu-directive></menu-directive>'
                    }
                }
            })
            .state('search',
            {
                url: '/search/:id',
                views: {
                    'mainView': {
                        template: '<search-directive></search-directive>'
                    },
                    'menuView': {
                        template: '<menu-directive></menu-directive>'
                    }
                }
            })
            .state('searchall',
            {
                url: '/search',
                views: {
                    'mainView': {
                        template: '<search-directive></search-directive>'
                    },
                    'menuView': {
                        template: '<menu-directive></menu-directive>'
                    }
                }
            })
            .state('cusearch',
            {
                url: '/cusearch',
                views: {
                    'mainView': {
                        template: '<cu-search-directive></cu-search-directive>'
                    },
                    'menuView': {
                        template: '<menu-directive></menu-directive>'
                    }
                }
            })
            .state('cusearchall',
            {
                url: '/cusearch/:id',
                views: {
                    'mainView': {
                        template: '<cu-search-directive></cu-search-directive>'
                    },
                    'menuView': {
                        template: '<menu-directive></menu-directive>'
                    }
                }
            })
            .state('cuso',
            {
                url: '/:cdsid/view',
                views: {
                    'mainView': {
                        template: '<cuso-profile-directive></cuso-profile-directive>'
                    },
                    'menuView': {
                        template: '<menu-directive></menu-directive>'
                    }
                }
            })

            .state('cu',
            {
                url: '/:cdsid/cu',
                views: {
                    'mainView': {
                        template: '<cu-profile-directive></cu-profile-directive>'
                    },
                    'menuView': {
                        template: '<menu-directive></menu-directive>'
                    }
                }
            })
            .state('about',
            {
                url: '/about',
                views: {
                    'mainView': {
                        template: '<about-directive></about-directive>'
                    },
                    'menuView': {
                        template: '<menu-directive></menu-directive>'
                    }
                }
            })
            .state('contact',
            {
                url: '/contact',
                views: {
                    'mainView': {
                        template: '<contact-directive></contact-directive>'
                    },
                    'menuView': {
                        template: '<menu-directive></menu-directive>'
                    }
                }
            })
            .state('logout',
            {
                url: '/logout',
                views: {
                    'mainView': {
                        template: '<logout-directive></logout-directive>'
                    },
                    'menuView': {
                        template: '<menu-directive></menu-directive>'
                    }
                }
            })
            .state('verifylogin',
            {
                url: '/verify/login',
                views: {
                    'mainView': {
                        template: '<verify-login-directive></verify-login-directive>'
                    },
                    'menuView': {
                        template: '<menu-directive></menu-directive>'
                    }
                }
            })
        .state('editcuso',
        {
            url: '/verify/:cdsid/edit',
            views: {
                'mainView': {
                    template: '<edit-cuso-directive></edit-cuso-directive>'
                },
                'menuView': {
                    template: '<menu-directive></menu-directive>'
                }
            }
        })
        .state('editowner',
        {
            url: '/verify/:cdsid/owner',
            views: {
                'mainView': {
                    template: '<edit-owner-directive></edit-owner-directive>'
                },
                'menuView': {
                    template: '<menu-directive></menu-directive>'
                }
            }
        })
        .state('editclient',
        {
            url: '/verify/:cdsid/client',
            views: {
                'mainView': {
                    template: '<edit-client-directive></edit-client-directive>'
                },
                'menuView': {
                    template: '<menu-directive></menu-directive>'
                }
            }
        })
        .state('editrelation',
        {
            url: '/verify/:cdsid/relation',
            views: {
                'mainView': {
                    template: '<edit-relation-directive></edit-relation-directive>'
                },
                'menuView': {
                    template: '<menu-directive></menu-directive>'
                }
            }
        })
        .state('confirm',
        {
            url: '/verify/:cdsid/confirm',
            views: {
                'mainView': {
                    template: '<confirm-directive></confirm-directive>'
                },
                'menuView': {
                    template: '<menu-directive></menu-directive>'
                }
            }
        });

        //RestangularProvider.setBaseUrl('http://localhost:53958');
        //RestangularProvider.setBaseUrl('http://tiger:8082');
        RestangularProvider.setBaseUrl('https://callahanws.com');
        RestangularProvider.setDefaultHeaders({
            'Accept': 'application/json, text/plain, */*',
            'Access-Control-Allow-Headers': 'Accept, X-Requested-With',
            'Access-Control-Allow-Methods': 'GET, POST, PUT, DELETE',
            'Access-Control-Allow-Origin': '*'
        });

        RestangularProvider.setResponseExtractor(function (response, operation) {
            // This is a get for a list
            var newResponse;
            if (operation === "getList") {
                if (_.isArray(response.Data))
                    newResponse = response.Data;
                else if (_.isArray(response))
                    newResponse = response;
                else
                    newResponse = [];

                newResponse.Metadata = response.Metadata;
            } else {
                // This is an element
                newResponse = response;
            }
            return newResponse;
        });
    }]);


    //
    // For this trivial demo we have just a unique MainController 
    // for everything
    //
    app.controller('MainController', ['$rootScope', '$scope', function ($rootScope, $scope) {
        // Needed for the loading screen
        $rootScope.$on('$routeChangeStart', function () {
            $scope.loading = true;
        });

        $rootScope.$on('$routeChangeSuccess', function () {
            $scope.loading = false;
        });
        $scope.stylesheet = "style/bootstrap.css";

        $scope.$on('$routeChangeStart', function (scope, next, current) {
            var moduleName = next.$$route.moduleName;
            if (moduleName) {
                if (moduleName == 'sales') {

                }
            }
        });

    }]);

    app.service('error', ['api', 'cusoUser', function (api, cusoUser) {
        return {

            search: function (location, errorMessage) {
                var log = {
                    MemberId: cusoUser.memberId(),
                    Location: location,
                    ErrorMessage: errorMessage
                };
                $log.error(location, errorMessage);
                return api.error.insert(log).then(function (data) {
                }, function (error) {
                });
            }
        };
    }]);



})();