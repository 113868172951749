
(function () {
    'use strict';
    var app = angular.module("cusoAnalyzer");
    app.directive("menuDirective", function () {
        return {
            templateUrl: 'menu.html',
            controller: 'MenuCtrl',
            controllerAs: 'menuVm'

        };
    });
    angular.module('cusoAnalyzer')
        .controller('MenuCtrl', MenuCtrl);

    MenuCtrl.$inject = ['$location', 'cusoSession', 'searchCriteria', 'cuSearchCriteria'];

    function MenuCtrl($location, cusoSession, searchCriteria, cuSearchCriteria) {

        var vm = this;
        vm.cusoSearch = false;
        vm.cuSearch = false;
        vm.about = false;
        vm.contact = false;
        vm.showMenu = true;
        vm.toSearch = toSearch;
        vm.toCuSearch = toCuSearch;
        vm.currentPath = $location.path();

        vm.TrackId = cusoSession.getTrackId();
        
        if (vm.currentPath.indexOf('/search') > -1) {
            vm.cusoSearch = true;
            vm.cuSearch = false;
            vm.about = false;
            vm.contact = false;
        }
        else if (vm.currentPath.indexOf('/cusearch') > -1) {
            vm.cusoSearch = false;
            vm.cuSearch = true;
            vm.about = false;
            vm.contact = false;
            vm.showMenu = true;
        }
        else if (vm.currentPath.indexOf('/about') > -1) {
            vm.cusoSearch = false;
            vm.cuSearch = false;
            vm.about = true;
            vm.contact = false;
            vm.showMenu = true;
        }
        else if (vm.currentPath.indexOf('/contact') > -1) {
            vm.cusoSearch = false;
            vm.cuSearch = false;
            vm.about = false;
            vm.contact = true;
            vm.showMenu = true;
            if (vm.TrackId == undefined) {
                vm.showMenu = false;
            }
        }
        else if (vm.currentPath.indexOf('/login') > -1 || vm.currentPath.indexOf('/logout') > -1 || vm.currentPath.indexOf('/verify') > -1)
        {
            vm.showMenu = false;
        }

        function toSearch() {
            searchCriteria.resetSearch();
            $location.path('#/search');
        }

        function toCuSearch() {
            cuSearchCriteria.resetSearch();
            $location.path('#/cusearch');
        }
    }
})();

