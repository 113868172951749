
(function () {
    'use strict';
    var app = angular.module("cusoAnalyzer");
    app.directive("editCusoDirective", function () {
        return {
            templateUrl: 'verify/editCuso.html',
            controller: 'EditCusoCtrl',
            controllerAs: 'vm'

        };
    });
    angular.module('cusoAnalyzer')
        .controller('EditCusoCtrl', EditCusoCtrl);

    EditCusoCtrl.$inject = ['cusoApi', 'cusoVerifyApi', 'verificationSession', '$stateParams', '$location', '$window'];

    function EditCusoCtrl(cusoApi, cusoVerifyApi, verificationSession, $stateParams, $location, $window) {

        var vm = this;
        vm.user = verificationSession.getUser();
        vm.cdsId = parseInt($stateParams.cdsid);
        vm.Model =  {};
        vm.save = save;
        vm.reset = reset;
        vm.checkService = checkService;
        vm.ServiceIds = [];
        vm.initial = true;
        init();

        function init() {
            //check session
            vm.sessionId = verificationSession.getTrackId();
            if (vm.sessionId == undefined) {
                $location.path('/verify/login');
            }

            //state list
            cusoVerifyApi.selection.states(vm.cdsId).then(function (data) {
                vm.statelist = data;
            });

            //service list
           
            cusoVerifyApi.service.withPreSelection(vm.cdsId).then(function (data) {
                vm.services = data;
            });

            //general info data
            cusoVerifyApi.cuso.byCdsId(vm.cdsId).then(function (data) {
                vm.Model = data.Data;
                vm.originalInvestor = data.Data.NeedInvestor;
                if (data.Data.NeedInvestor != null) vm.Model.Investor = true;
                else vm.Model.Investor = false;
            }, function (error) {
            });
        }

        function checkService(option) {
            //console.log(option);
            if (option.ServiceId == option.ParentServiceId) {
                option.Selected = !option.Selected;
                //all the children whill be conformed
                angular.forEach(vm.services, function (value, key, obj) {
                    if (value.ParentServiceId == option.ServiceId)
                        value.Selected = option.Selected;
                });
            } else {
                option.Selected = !option.Selected;
            }

        }

        function save() {
            //investor from bool -> datetime
            if (vm.originalInvestor == null && vm.Model.Investor)
                vm.Model.NeedInvestor = new Date((new Date()).setMonth(8));
            else if (!vm.Model.Investor) vm.Model.NeedInvestor = null;

            //save general info
            cusoVerifyApi.cuso.update(vm.Model).then(function () { });
            cusoApi.cuso.addUpdateCuso(vm.Model).then(function () { });

            //save service list
            angular.forEach(vm.services,
                   function (value, key, obj) {
                       if ((value.ParentServiceId !== value.ServiceId) && value.Selected)
                           vm.ServiceIds.push(value.ServiceId);
                   });
            cusoApi.service.save(vm.cdsId, vm.ServiceIds).then(function () { })
                .then(function () {
                    cusoVerifyApi.service.save(vm.cdsId, vm.ServiceIds).then(function () { });
                });

            $location.path('verify/' + vm.cdsId + '/relation');
        }

        function reset() {
            $window.location.reload();
        }
    }
})();