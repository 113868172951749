(function () {
    'use strict';
    var app = angular.module("cusoAnalyzer");
    app.directive("editClientDirective", function () {
        return {
            templateUrl: 'verify/client.html',
            controller: 'CllientCtrl',
            controllerAs: 'vm'

        };
    });
    angular.module('cusoAnalyzer')
        .controller('CllientCtrl', CllientCtrl);

    CllientCtrl.$inject = ['cusoVerifyApi', 'verificationSession', '$stateParams', '$location'];

    function CllientCtrl(cusoVerifyApi, verificationSession, $stateParams, $location) {
        var vm = this;
        vm.user = verificationSession.getUser();
        vm.cdsId = parseInt($stateParams.cdsid);
        vm.redirect = redirect;
        vm.addClient = addClient;
        vm.deleteClient = deleteClient;
        vm.criteria = {};
        vm.newClient = {
            CusoCdsId: vm.cdsId,
            CuCdsId: 0,
            IsActive: true
        }
        vm.SelectionList = [];
        vm.moduleType = 'cu';
        vm.exclusionListName = 'Client';

        init();
        function init() {
            //check session
            vm.sessionId = verificationSession.getTrackId();
            if (vm.sessionId == undefined) {
                $location.path('/verify/login');
            }

            cusoVerifyApi.cuso.clients(vm.cdsId).then(function (data) {
                vm.clients = data;
            }, function (error) {
            });
        }

        function addClient() {
            if (vm.CuName.length < 3 || vm.SelectionList.length < 1) return;
            angular.forEach(vm.SelectionList, function (value, key) {
                if (value.ItemName === vm.CuName)
                    vm.newClient.CuCdsId = value.ItemId;
            });
            cusoVerifyApi.cuso.insertClient(vm.newClient).then(function () { });
            vm.newClient = {
                ItemId: vm.newClient.CuCdsId,
                ItemName: vm.CuName.split('(')[0]//,
                //CusoCdsId: vm.newClient.CusoCdsId
            };
            vm.clients.push(vm.newClient);
            vm.newClient = {
                CusoCdsId: vm.cdsId,
                CuCdsId: 0,
                TotalInvestments: 0.00,
                TotalAmountLoaned: 0.00,
                TotalCashOutlay: 0.00,
                IsActive: true
            }
            vm.CuName = '';
        }

        function deleteClient(model) {
            cusoVerifyApi.cuso.deleteClient(model.ItemId, vm.cdsId).then(function () {
                var index = vm.clients.indexOf(model);
                vm.clients.splice(index, 1);
            });
        }

        function redirect(direction) {
            if (direction === 'prev') $location.path('verify/' + vm.cdsId + '/owner');
            else $location.path('verify/' + vm.cdsId + '/confirm');
        }
    }
})();